import CPButton from "components/CP/CPButton"
import Link from "next/link"

export default function Slide1({ isActive, isMobile }) {
  return (
    <div className="lg:mt-[5vh] md:mt-[50px]">
      <h1
        className={`font-bold home-header f-25 mb-4 font-roboto-slab ${
          isActive && "card-in"
        }`}
        style={isMobile ? { lineHeight: "35px" } : {}}
      >
        Work Together,
        <br />
        Achieve Smarter
      </h1>
      <p
        className={`md:mb-6 mb-4 home-description f-13 ${
          isActive && "card-in"
        }`}
      >
        Birdflocks is a Smart Hub that empowers Architecture, Engineering, and
        Construction (AEC) professionals to connect, collaborate, and overcome
        industry challenges efficiently.
      </p>

      <CPButton
        type="primary"
        className={`px-8 py-0 ${isActive && "card-in"}`}
        style={{ minWidth: "200px" }}
      >
        <Link
          passHref
          prefetch={false}
          href="/signup"
          className="block py-1 font-normal"
        >
          <span>Build Your Profile</span>
        </Link>
      </CPButton>
    </div>
  )
}
