import CPButton from "components/CP/CPButton"
import SemiFooter from "components/CustomFields/SemiFooter"
import Link from "next/link"

export default function Slide4({ isActive, isMobile }) {
  return (
    <div
      className="lg:pt-[10vh] md:pt-[50px]"
      style={{
        height: "100%",
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <h1
          className={`font-bold home-header f-25 mb-4 font-roboto-slab ${
            isActive && "card-in"
          }`}
          style={isMobile ? { lineHeight: "35px" } : {}}
        >
          Connecting Minds,
          <br />
          Inspiring Progress,
          <br />
          Collaboration Starts here
        </h1>
        <p
          className={`md:mb-6 mb-4 home-description f-13 ${
            isActive && "card-in"
          }`}
        >
          It all begins with vibrant communities where professionals unite,
          paving the way for innovation. As our platform evolves, our commitment
          remains steadfast in creating innovative solutions that optimize
          operations and foster collaboration within and between companies.
        </p>
        <CPButton
          type="primary"
          className={`px-8 py-0 ${isActive && "card-in"}`}
          style={{ minWidth: "200px" }}
        >
          <Link passHref prefetch={false} href="/signup" className="block py-1">
            <span>Build Your Profile</span>
          </Link>
        </CPButton>
      </div>

      <div style={{ paddingBottom: "120px" }} className="card-in">
        <SemiFooter />
      </div>
    </div>
  )
}
