import { Button, Card, Skeleton, Typography } from "antd"
import Link from "next/link"
import { useEffect, useState } from "react"
import apiService from "services/apiService"
import { notifyToast } from "services/notifyToast"

const { Text } = Typography
const { Paragraph } = Typography

export default function FeedCommunities({ className = "" }) {
  const [items, setItem] = useState([])
  const [showMore, setMore] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await apiService.get("/api/community/communities")
      setItem(res?.data?.data)
    } catch (e) {
      await notifyToast(e)
    }

    if (setLoading) {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Card
      className={`${className} custom-card f-14 border border-gray-300 rounded-lg`}
      styles={{ body: { padding: 0 } }}
    >
      <Text className="f-19 block mb-3 px-5 pt-5" strong>
        Communities
      </Text>

      {loading ? (
        <div className="px-5 pb-4 -mt-2">
          <Skeleton active paragraph={{ rows: 2 }} className="small-skeleton" />
        </div>
      ) : items?.length !== 0 ? (
        <>
          <div className="overflow-y-auto overflow-x-hidden community-feed-container pb-3">
            {items?.map(
              (item, index) =>
                index < (showMore ? items?.length : 4) && (
                  <div
                    key={index}
                    className="flex items-center py-2 mb-1 hover-primary px-4 cursor-pointer text-gray-900"
                  >
                    <Link
                      passHref
                      prefetch={false}
                      href={`/community/${item?.id}`}
                    >
                      <div
                        className="border border-solid border-white bg-cover cursor-pointer bg-center overflow-hidden rounded-lg"
                        style={{
                          width: 65,
                          height: 65,
                          minWidth: 65,
                          minHeight: 65,
                          boxShadow: "0 5px 20px 0 rgba(0, 0, 0, 0.16)",
                          backgroundImage: `url(${
                            item?.logo_url ||
                            item?.cover_url ||
                            "/assets/onBoarding/profile_avatar.jpg"
                          })`,
                        }}
                      />
                    </Link>

                    <div className="px-3">
                      <Link
                        passHref
                        prefetch={false}
                        href={`/community/${item?.id}`}
                      >
                        <Paragraph
                          ellipsis={{ rows: 2, tooltip: false }}
                          className="m-0 f-15"
                        >
                          <span
                            className="inline-block"
                            style={{ lineHeight: 1.3 }}
                          >
                            {item?.name}
                          </span>
                        </Paragraph>
                      </Link>
                      {item?.location && (
                        <Paragraph
                          ellipsis={{ rows: 2, tooltip: false }}
                          className="m-0 f-14 text-gray-500"
                        >
                          <span>
                            <span className="icon-ic_room_24px mr-1" />
                            {item?.location}
                          </span>
                        </Paragraph>
                      )}
                    </div>
                  </div>
                )
            )}
          </div>

          {items.length > 5 && (
            <Button
              type="link"
              className="py-0 px-4 text-primary f-12 mb-2"
              onClick={() => setMore(!showMore)}
            >
              {showMore ? "-" : "+"} Show {showMore ? "less" : "more"}
            </Button>
          )}
        </>
      ) : (
        <div className="px-5 pb-4">
          <div className="text-gray-500 mb-4 -mt-2">No items to display</div>
          <Link
            passHref
            key="communities"
            href="/communities"
            className="text-primary cursor-pointer mb-4"
          >
            <div className="text-primary cursor-pointer">
              <span className="icon-ic_add_circle_outline_24px mr-2"></span>
              <span className="f-14">Join New Community</span>
            </div>
          </Link>
        </div>
      )}
    </Card>
  )
}
