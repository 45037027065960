import { Space } from "antd"
import Link from "next/link"

export default function SemiFooter() {
  return (
    <div className="z-0 md:block hidden px-1">
      <Space size={1} className="flex-wrap mb-2">
        <Link
          passHref
          prefetch={false}
          href="/privacy"
          className="inline-block hover:text-primary p-1 f-12 text-gray-500"
        >
          <span>Privacy</span>
        </Link>
        <span>.</span>
        <Link
          passHref
          prefetch={false}
          href="/conditions"
          className="inline-block hover:text-primary p-1 f-12 text-gray-500"
        >
          <span>Terms</span>
        </Link>
        <span>.</span>
        <Link
          passHref
          prefetch={false}
          href="/about"
          className="inline-block hover:text-primary p-1 f-12 text-gray-500"
        >
          <span>About Us</span>
        </Link>
        <span>.</span>
        <Link
          passHref
          prefetch={false}
          href="/career"
          className="inline-block hover:text-primary p-1 f-12 text-gray-500"
        >
          <span>Career</span>
        </Link>
      </Space>
    </div>
  )
}
