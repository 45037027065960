import { Avatar, Badge, Card } from "antd"
import { AppContext } from "components/AppContext"
import AddPostModal from "components/Feed/AddPost/AddPostModal"
import { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  addPostState,
  setReference,
  toggleDialog,
} from "states/slices/addPostSlice"
import { profileState } from "states/slices/profileReducer"

export default function AddPost({
  url,
  onFetch,
  disablePrivacy = false,
  className = "",
}) {
  const dispatch = useDispatch()
  const { userInfo } = useSelector(profileState)
  const { showPanel } = useSelector(addPostState)
  const { companyDetails = null } = useContext(AppContext) || {}

  useEffect(() => {
    if (!showPanel) {
      dispatch(setReference(null))
    }
  }, [showPanel])

  return (
    <Card
      className={`${className} custom-card f-14 border border-gray-300 rounded-lg`}
      styles={{ body: { padding: "20px 13px" } }}
    >
      <div className="flex justify-center items-center">
        <Badge
          dot
          style={{ backgroundColor: "#00a23d" }}
          offset={[-8, 52]}
          size={20}
        >
          <Avatar
            size={60}
            className="bg-white border border-gray-300 flex-shrink-0"
            src={
              companyDetails?.img ||
              userInfo?.icon ||
              "/assets/onBoarding/profile_avatar.jpg"
            }
          />
        </Badge>
        <div
          className="ml-4 p-4 bg-gray-100 rounded-xl ant-btn-link flex-grow flex justify-start items-center cursor-pointer"
          onClick={() => dispatch(toggleDialog(true))}
        >
          {companyDetails ? (
            <span className="text-gray-500">
              Write your mind with company profile...
            </span>
          ) : (
            <span className="text-gray-500">
              What&apos;s on your mind, {userInfo?.first_name}?
            </span>
          )}
        </div>
      </div>

      {showPanel && (
        <AddPostModal
          url={url}
          onFetch={onFetch}
          disablePrivacy={disablePrivacy}
        />
      )}
    </Card>
  )
}
