import { Button, Card, Skeleton } from "antd"
import AddPost from "components/Feed/AddPost"
import FeedListViewContents from "components/Feed/FeedListViewContents"
import Search from "components/Feed/Search"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { toggleDialog } from "states/slices/addPostSlice"
import { buildSearchQuery } from "utils/global"
import useFetch from "utils/useFetch"

export default function FeedListView({
  categories = [],
  addPostUrl,
  url,
  placeholder,
  notFound = null,
  showAddPost = false,
  disablePrivacy = false,
}) {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [category, setCategory] = useState("")
  const [searchFilters, setSearchFilters] = useState(null)
  const [fetchPagination, setFetchPagination] = useState(null)

  const [loadingData, fetchedData, { refresh }] = useFetch(
    `${url}?page=${page}&sort=most_recent${buildSearchQuery(searchFilters)}`,
    {
      skip: false,
      hideError: true,
    }
  )

  const onFetch = () => {
    setPage(1)
    refresh()
  }

  const fillData = () => {
    if (fetchedData?.data?.length > 0) {
      if (page === 1) {
        setFetchPagination([...fetchedData.data])
      } else {
        setFetchPagination([...(fetchPagination || []), ...fetchedData.data])
      }
    } else if (page === 1) {
      setFetchPagination([])
    }
  }

  const renderNotFound = (
    <>
      <img
        src="/assets/onBoarding/feed.png"
        alt="no result"
        className="mx-auto mb-7 mt-12 w-72"
      />

      <div className="text-gray-500 text-center mb-6">
        <span>
          Your feed is empty! Grow your connection and,
          <br />
          share with them what&apos;s on your mind.
        </span>
      </div>

      <div
        className="text-primary text-center cursor-pointer mb-10"
        onClick={() => dispatch(toggleDialog(true))}
      >
        <span className="icon-ic_add_circle_outline_24px mr-2" />
        <span className="f-14">Add Post</span>
      </div>
    </>
  )

  const feedLoading = (
    <>
      <Card
        className="custom-card f-14 border border-gray-300 mb-3 rounded-lg"
        styles={{ body: { padding: 14 } }}
      >
        <Skeleton active paragraph={{ rows: 3 }} />
      </Card>
      <Card
        className="custom-card f-14 border border-gray-300 mb-3 rounded-lg"
        styles={{ body: { padding: 14 } }}
      >
        <Skeleton active paragraph={{ rows: 3 }} />
      </Card>
      <Card
        className="custom-card f-14 border border-gray-300 mb-3 rounded-lg"
        styles={{ body: { padding: 14 } }}
      >
        <Skeleton active paragraph={{ rows: 3 }} />
      </Card>
      <Card
        className="custom-card f-14 border border-gray-300 mb-3 rounded-lg"
        styles={{ body: { padding: 14 } }}
      >
        <Skeleton active paragraph={{ rows: 3 }} />
      </Card>
    </>
  )

  useEffect(() => {
    fillData()
  }, [fetchedData])

  useEffect(() => {
    setSearchFilters({ ...searchFilters, category, search_q: search })
  }, [search, category])

  return (
    <>
      <Search
        search={search}
        loading={loadingData}
        placeholder={placeholder}
        setSearch={event => {
          setPage(1)
          setSearch(event)
        }}
        className="mb-4"
      />

      {showAddPost && (
        <AddPost
          url={addPostUrl}
          onFetch={onFetch}
          disablePrivacy={disablePrivacy}
        />
      )}

      <div className="flex items-center my-4">
        {categories?.map((item, index) => (
          <Fragment key={index}>
            <Button
              type="link"
              className={`${
                category === item.value ? " text-primary" : "text-gray-500"
              } px-1 m-0`}
              onClick={() => setCategory(item.value)}
            >
              {item.title}
            </Button>
            {categories.length !== index + 1 ? (
              <span className="text-gray-500 px-1">/</span>
            ) : (
              <span className="mr-3 inline-block" />
            )}
          </Fragment>
        ))}

        <div className="border-t-2 border-gray-300 flex-grow" />
      </div>

      {!(loadingData && page === 1) && fetchPagination?.length > 0 && (
        <FeedListViewContents
          fetchedData={fetchedData}
          fetchPagination={fetchPagination}
          setPage={setPage}
          refreshData={onFetch}
          onFetch={onFetch}
        />
      )}

      {loadingData && page === 1 && feedLoading}
      {!loadingData &&
        fetchPagination?.length === 0 &&
        (notFound || renderNotFound)}
    </>
  )
}
