import CPButton from "components/CP/CPButton"
import Link from "next/link"

export default function Slide3({ isActive, isMobile }) {
  return (
    <div className="lg:mt-[25vh] md:mt-[50px]">
      <h1
        className={`font-bold home-header f-25 mb-4 font-roboto-slab ${
          isActive && "card-in"
        }`}
        style={isMobile ? { lineHeight: "35px" } : {}}
      >
        Elevate Your Brand,
        <br />
        Spotlight Your Expertise
      </h1>
      <p
        className={`md:mb-6 mb-4 home-description f-13 ${
          isActive && "card-in"
        }`}
      >
        Stand out in the industry by showcasing your expertise, sharing your
        portfolio, and highlighting your projects and services. With Birdflocks,
        you can establish an impressive online presence that attracts new
        opportunities and put you ahead of the competition.
      </p>

      <CPButton
        type="primary"
        className={`px-8 py-0 ${isActive && "card-in"}`}
        style={{ minWidth: "200px" }}
      >
        <Link passHref prefetch={false} href="/signup" className="block py-1">
          <span>Build Your Profile</span>
        </Link>
      </CPButton>
    </div>
  )
}
