import { SearchOutlined } from "@ant-design/icons"
import { Button, Input } from "antd"
import { useEffect, useState } from "react"

export default function Search({
  search,
  disabled,
  setSearch,
  placeholder,
  loading = false,
  className = "",
}) {
  const [searchText, setSearchedText] = useState("")
  const [isTyping, setIsTyping] = useState(false)
  const [timeOut, setTimeOut] = useState(null)

  useEffect(() => {
    setSearchedText(search)
  }, [search])

  /**
   * on search
   */
  useEffect(() => {
    setIsTyping(true)
    if (timeOut) {
      clearTimeout(timeOut)
    }
    setTimeOut(
      setTimeout(() => {
        setIsTyping(false)
      }, 1000)
    )

    if (!searchText) {
      setSearch("")
    }
  }, [searchText])

  useEffect(() => {
    if (!isTyping) {
      onSubmit(searchText)
    }
  }, [isTyping])

  const onSubmit = () => {
    setSearch(searchText)
  }

  return (
    <form className={className} onSubmit={event => event.preventDefault()}>
      <Input
        style={{ height: 45 }}
        allowClear
        value={searchText}
        className="px-2 rounded-lg"
        disabled={disabled || (!searchText && loading)}
        placeholder={placeholder || "Search"}
        prefix={
          <Button
            type="link"
            className="p-0"
            loading={loading}
            style={{ height: 17, lineHeight: 0, marginTop: -3 }}
          >
            <SearchOutlined className="f-17 text-primary" />
          </Button>
        }
        onChange={event => setSearchedText(event.target.value)}
        onBlur={event => setSearchedText(event.target.value)}
      />
    </form>
  )
}
