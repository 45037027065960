import { Card, Col, Row, Typography } from "antd"
import SemiFooter from "components/CustomFields/SemiFooter"
import FeedCommunities from "components/Feed/FeedCommunities"
import FeedListView from "components/Feed/FeedListView"
import FeedProfile from "components/Feed/FeedProfile"
import FeedTopics from "components/Feed/FeedTopics"
import Landing from "components/Landing"
import { NextSeo } from "next-seo"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import StickyBox from "react-sticky-box"
import { profileState } from "states/slices/profileReducer"
import { wrapper } from "states/store"
import { serverSideProps } from "utils/serverSideProps"

const { Text } = Typography

export default function Index() {
  const { userInfo } = useSelector(profileState)

  const view = useMemo(
    () =>
      userInfo ? (
        <Row className="mh-screen mb-14">
          <Col
            xs={{ span: 22, offset: 1 }}
            lg={{ span: 20, offset: 2 }}
            xl={{ span: 18, offset: 3 }}
            xxl={{ span: 14, offset: 5 }}
          >
            <Row
              className="my-4"
              gutter={[15, 15]}
              style={{ backgroundColor: "#f2f2f2" }}
            >
              <Col xs={24} md={8} xxl={7}>
                <StickyBox offsetTop={72} offsetBottom={10}>
                  <FeedProfile className="mb-4" />
                  <FeedCommunities className="mb-4" />

                  <Card
                    styles={{ body: { padding: "22px 22px 5px" } }}
                    className="mb-4 custom-card f-14 m-0 border border-gray-300 rounded-lg"
                  >
                    <Text className="f-19 block" strong>
                      Topics
                    </Text>

                    <FeedTopics url="/api/network/feed/topics" feed />
                  </Card>

                  <SemiFooter />
                </StickyBox>
              </Col>
              <Col xs={24} md={16} xxl={17}>
                <FeedListView
                  addPostUrl={"/api/network/post"}
                  url="/api/network/feed"
                  showAddPost
                  type="feed"
                  placeholder="Search in feed..."
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Landing />
      ),
    [userInfo]
  )

  return (
    <>
      <NextSeo
        title="Smart Hub For Architecture, Engineering, Construction | Birdflocks"
        description="Birdflocks empowers the AEC (Architecture, Engineering, Construction) professionals to connect, collaborate, and overcome industry challenges."
        openGraph={{
          title:
            "Smart Hub For Architecture, Engineering, Construction | Birdflocks",
          site_name:
            "Smart Hub For Architecture, Engineering, Construction | Birdflocks",
          description:
            "Birdflocks empowers the AEC (Architecture, Engineering, Construction) professionals to connect, collaborate, and overcome industry challenges.",
        }}
      />
      {view}
    </>
  )
}

export const getServerSideProps = wrapper.getServerSideProps(serverSideProps)
