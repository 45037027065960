import CPButton from "components/CP/CPButton"
import Link from "next/link"

export default function Slide2({ isActive, isMobile }) {
  return (
    <div className="lg:mt-[10vh] md:mt-[50px]">
      <h1
        className={`font-bold home-header f-25 mb-4 font-roboto-slab ${
          isActive && "card-in"
        }`}
        style={isMobile ? { lineHeight: "35px" } : {}}
      >
        Expand Your Network,
        <br />
        Amplify Remarkable
        <br />
        Opportunities
      </h1>
      <p
        className={`md:mb-6 mb-4 home-description f-13 ${
          isActive && "card-in"
        }`}
      >
        Explore our curated communities, where professionals gather to connect,
        share challenges and solutions, and showcase their projects based on
        common interests and values. By joining our network, you&apos;ll unlock
        unlimited opportunities and connect with subject matter experts who can
        propel your success.
      </p>

      <CPButton
        type="primary"
        className={`px-8 py-0 ${isActive && "card-in"}`}
        style={{ minWidth: "200px" }}
      >
        <Link
          passHref
          prefetch={false}
          href="/pages/communities"
          className="block py-1"
        >
          <span>Explore</span>
        </Link>
      </CPButton>
    </div>
  )
}
